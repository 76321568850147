<template>
    <VField
        v-slot="{ field, errorMessage, meta }"
        v-model="model"
        :name="name"
        :rules="rules"
        type="checkbox"
        :value="true"
        :unchecked-value="false">
        <div v-bind="$attrs">
            <input
                v-bind="field"
                :id="name"
                :value="true"
                type="checkbox"
                :disabled="disabled"
                :class="[inputClasses, !meta.valid && meta.touched ? inputInvalidClasses : '']">

            <label
                :for="name"
                :class="labelClasses">
                <slot />
                {{ label }}
            </label>

            <div :class="errorClasses">
                {{ errorMessage }}
            </div>
        </div>
    </VField>
</template>

<script setup>
const props = defineProps({
    modelValue: {},
    name: {
        type: String,
        default: '',
        required: true
    },
    type: {
        type: String,
        default: 'text'
    },
    label: {
        type: String,
        default: ''
    },
    labelClasses: {
        type: String,
        default: 'form-label'
    },
    inputClasses: {
        type: String,
        default: 'form-checkbox'
    },
    inputInvalidClasses: {
        type: String,
        default: 'is-invalid'
    },
    errorClasses: {
        type: String,
        default: 'invalid-feedback'
    },
    rules: {
        type: [String, Array, Object],
        description: 'Vee validate validation rules',
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['update:modelValue'])

const model = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue)
    }
});
</script>
